<template>
    <div class="loginbox">
        <div class="back-icon">
            <van-icon name="arrow-left" @click="$router.go(-1)" />
        </div>
<div class="loginBody-image" style="height: 4rem;">
    <!-- 图片已移除 -->
</div>
        <div class="loginBody-found">
            <van-tabs line-width="0px" line-height="0px">
                <!--  <van-tab :title="$t('forget.phone_get')">
          <van-row>
            <van-cell-group>
              <van-field
                v-model="loginform.username"
                clearable
                :placeholder="$t('forget.enterphone')"
              >
                <template #left-icon>
                  <van-icon
                    class="iconfont"
                    class-prefix="icon"
                    name="shouji"
                  />
                </template>
                <template #label>
                  <van-dropdown-menu active-color="#1989fa">
                    <van-dropdown-item
                      v-model="select_quhao"
                      :options="option"
                    />
                  </van-dropdown-menu>
                </template>
              </van-field>
              <van-field
                v-model="loginform.sms"
                clearable
                center
                :placeholder="$t('forget.entercode')"
                class="smsbtn"
              >
                <template #left-icon>
                  <van-icon class="iconfont" class-prefix="icon" name="mima" />
                </template>
                <template #button>
                  <van-button
                    v-if="sendsmsbtn"
                    size="small"
                    type="primary"
                    @click.prevent="sendsms"
                    >{{ $t('forget.sendcode') }}</van-button
                  >
                  <van-button size="small" disabled v-else
                    >{{ sendsmstime }}s</van-button
                  >
                </template>
              </van-field>
              <van-field
                v-model="loginform.password"
                :type="type"
                :right-icon="eye"
                :placeholder="$t('forget.enterpassword')"
                @click-right-icon="openeye"
              >
                <template #left-icon>
                  <van-icon class="iconfont" class-prefix="icon" name="mima" />
                </template>
              </van-field>
              <van-field
                v-model="loginform.password2"
                :type="type"
                :right-icon="eye"
                :placeholder="$t('forget.enterpassword')"
                @click-right-icon="openeye"
              >
                <template #left-icon>
                  <van-icon class="iconfont" class-prefix="icon" name="mima" />
                </template>
              </van-field>
            </van-cell-group>
          </van-row>
          <van-button class="mybtn" :loading="loading" @click="getpass">{{
            $t('forget.getpass')
          }}</van-button>
        </van-tab> -->
                <van-tab :title="$t('forget.email_get')">
                    <van-row>
                        <van-cell-group>
                            <van-field v-model="loginform.username" clearable :placeholder="$t('forget.enteremail')">
                                <template #left-icon>
                                    <van-icon class="iconfont" class-prefix="icon" name="youxiang" />
                                </template>
                            </van-field>
                            <van-field v-model="loginform.sms" clearable center :placeholder="$t('forget.entercode')"
                                class="smsbtn">
                                <template #left-icon>
                                    <van-icon class="iconfont" class-prefix="icon" name="mima" />
                                </template>
                                <template #button>
                                    <van-button v-if="sendemailbtn" size="small" type="primary"
                                        @click.prevent="sendemail">{{ $t('forget.sendcode') }}</van-button>
                                    <van-button size="small" disabled v-else>{{ sendemailtime }}s</van-button>
                                </template>
                            </van-field>
                            <van-field v-model="loginform.password" :type="type" :right-icon="eye"
                                :placeholder="$t('forget.enterpassword')" @click-right-icon="openeye">
                                <template #left-icon>
                                    <van-icon class="iconfont" class-prefix="icon" name="mima" />
                                </template>
                            </van-field>
                            <van-field v-model="loginform.password2" :type="type" :right-icon="eye"
                                :placeholder="$t('forget.enterpassword')" @click-right-icon="openeye">
                                <template #left-icon>
                                    <van-icon class="iconfont" class-prefix="icon" name="mima" />
                                </template>
                            </van-field>
                        </van-cell-group>
                    </van-row>
                    <van-button class="mybtn" :loading="loading" @click="getpass">{{
                        $t('forget.getpass')
                    }}</van-button>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            select_quhao: '',
            option: [],
            loginform: {
                username: '',
                password: '',
                password2: '',
                sms: ''
            },
            type: 'password',
            loading: false,
            checked: true,
            eye: 'closed-eye',
            sendsmsbtn: true,
            sendsmstime: 60,
            sendemailbtn: true,
            sendemailtime: 60
        }
    },
    created() {
        this.getlist()
    },
    methods: {
        async getlist() {
            const { data } = await this.$http.get('/home/index/getcode')
            if (data) {
                if (data.code === 200) {
                    this.option = data.data
                    this.select_quhao = data.data[0].value
                }
            }
        },
        // 查看密码
        openeye(event) {
            if (this.type === 'password') {
                this.eye = 'eye'
                this.type = 'text'
            } else {
                this.eye = 'closed-eye'
                this.type = 'password'
            }
        },
        // 手机号找回
        phone() {
            this.loading = true
        },
        async sendsms() {
            if (!this.loginform.username) {
                this.$toast.fail(this.$t('forget.enterphone'))
                return false
            }
            if (this.sendsmsbtn === false) {
                return false
            }
            const { data } = await this.$http.post('/home/index/sendsms', {
                phone: this.loginform.username,
                area: this.select_quhao
            })
            if (data) {
                if (data.code === 200) {
                    this.sendsmsbtn = false
                    this.sendsmstime = 60
                    this.$toast.success(this.$t('forget.codesent'))
                    const smsTimer = setInterval(() => {
                        this.sendsmstime--
                        if (this.sendsmstime <= 0) {
                            this.sendsmsbtn = true
                            clearInterval(smsTimer)
                        }
                    }, 1000)
                } else {
                    this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
                }
            }
        },
        // 邮箱找回
        email() {
            this.emailloading = true
        },
        async sendemail() {
            if (!this.loginform.username) {
                this.$toast.fail(this.$t('forget.enteremail'))
                return false
            }
            if (
                !/^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
                    this.loginform.username
                )
            ) {
                this.$toast.fail(this.$t('forget.emailformat'))
                return false
            }
            if (this.sendemailbtn === false) {
                return false
            }

            this.sendemailbtn = false
            const { data } = await this.$http.post('/home/index/sendemail', {
                email: this.loginform.username
            })
            if (data) {
                if (data.code === 200) {

                    this.sendemailtime = 60
                    this.$toast.success(this.$t('forget.codesent'))
                    const eaminTimer = setInterval(() => {
                        this.sendemailtime--
                        if (this.sendemailtime <= 0) {
                            this.sendemailbtn = true
                            clearInterval(eaminTimer)
                        }
                    }, 1000)
                } else {
                    this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
                    this.sendemailbtn = true
                }
            } else {
                this.sendemailbtn = true
            }
        },
        async getpass() {
            if (
                !this.loginform.password ||
                !this.loginform.sms ||
                !this.loginform.username
            ) {
                this.$toast.fail(this.$t('forget.complete'))
                return false
            }
            if (this.loginform.password !== this.loginform.password2) {
                this.$toast.fail(this.$t('forget.eqpass'))
                return false
            }
            const { data } = await this.$http.post(
                '/home/index/getpass',
                this.loginform
            )
            if (data) {
                if (data.code === 200) {
                    this.$toast.success(this.$t('commom.success'))
                    this.$router.push('/login')
                } else {
                    this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .van-cell {}

/deep/ .van-tabs__nav {}

/deep/ .van-field__control {
    color: var(--main-text-color); //输入字体颜色
}

.loginbox {
    height: 100%;
    width: 100%;
    background: url('../../assets/img/login-bg.png'); //找回密码页背景
    background-size: cover;
    position: relative;
    padding: 2.75rem 2rem 0 2rem;

    .back-icon {
        position: fixed;
        left: 4%;
        top: 2%;

        .van-icon {
            color: #004ea3; //后退键
        }
    }

    .loginBody-image {
        margin-top: 25%;
        height: 20%;
        z-index: 1;
    }

    .loginBody-found {
        margin-top: 10%;
        margin-left: 5%;
        width: 90%;
        padding-bottom: 3%;

        .van-dropdown-menu /deep/.van-dropdown-menu__bar {
            box-shadow: none;
            height: 1.5rem;
            background: transparent !important;
        }

        /deep/.van-tab {
            font-size: 1rem !important;
        }

        /deep/.van-tab--active {
            color: #004ea3;
            font-weight: 500;
        }

        .van-button {
            font-size: 0.8rem;
            background-color: rgb(0, 78, 163);
            color: white;
            padding: 5px;
            border-radius: 6px;
            border: none;
        }

        .van-field {
            padding: 20px 0 10px 0;
            font-size: 1rem;
        }

        /deep/.van-field__label {
            width: auto;
        }

        /deep/ .van-icon,
        /deep/.van-field__right-icon {
            color: #004ea3;
            font-size: 1.2rem;
        }

        .van-tab {
            font-size: 1rem;
        }

        .van-tab--active {
            color: #004ea3;
        }

        .mybtn {
            margin-top: 18%;
            width: 60%;
            height: 2.66667rem;
            font-size: 0.93333rem;
            color: #fff;
            background-color: var(--blue);
            border-radius: 1.6rem;
            border: none;
        }
    }

    .van-image__error,
    .van-image__img,
    .van-image__loading {
        display: block;
        width: 100%;
        height: 100%;
    }

    .van-icon-clear {
        color: #b3b3b3;
        font-size: 1rem;
    }

    .van-field__control {
        padding-left: 5px;
    }
}

.iconfont {
    color: #004ea3;
    font-size: 18px;
}
</style>
